import { useNetwork } from "@reactuses/core";
import React, { useEffect } from "react";
import { useSession } from "next-auth/react";
import { eventPublisher } from "@/services/event-publisher";
import { Alert } from "react-bootstrap";

const CheckConnection = () => {
  const { online, downlink } = useNetwork();
  const { data: session } = useSession();

  const safeDownlink = downlink ?? 0;
  const isSlowConnection = typeof downlink === "number" && downlink < 1;
  const networkSpeed = `${(safeDownlink * 1024).toFixed(2)} kb/s`;

  useEffect(() => {
    if (isSlowConnection && session?.user) {
      eventPublisher.logAnalyticsEvent({
        eventType: "Regular",
        actionName: "slowNetwork",
        additionalProperties: {
          networkSpeed: networkSpeed,
        }
      });
    }
  }, [isSlowConnection, session]);

  if (online && !isSlowConnection) return null;

  const alertMessage = !online ? (
    "Your internet connection is currently unavailable. Please check your network settings."
  ) : (
    <>
      Your internet speed is{" "}
      {networkSpeed !== "unknown" && <strong>{networkSpeed}</strong>} slower
      than usual. This may cause longer loading times. Please be patient while
      we retrieve the content.
    </>
  );

  return (
    <div className="position-relative" style={{ zIndex: 999999 }}>
      <Alert
        show={true}
        variant="warning"
        className="d-flex align-items-start m-0 border-0 rounded-0 show py-3 alert-animation"
        style={{
          backgroundColor: "#FFF4F4",
          transition: "opacity 0.3s ease-in-out",
          padding: "0.75rem 1rem",
        }}
      >
        <div className="d-flex w-100">
          <div className="d-flex">
            <div className="network-icon-container me-2">
              <img
                src="/images/network.png"
                alt="Network Status"
                width={24}
                height={24}
                className="text-danger network-icon"
              />
            </div>
            <span className="text-danger alert-message mb-0">
              {alertMessage}
            </span>
          </div>
        </div>
        <style jsx>{`
          .alert-message {
            font-size: 0.875rem;
          }

          @media (min-width: 769px) {
            .network-icon {
              width: 16px !important;
              height: 16px !important;
            }
          }

          @media (max-width: 768px) {
            .network-icon {
              width: 24px !important;
              height: 24px !important;
            }
            .network-icon-container {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .alert-message {
              font-size: 0.75rem !important;
            }
          }
        `}</style>
      </Alert>
    </div>
  );
};

export default CheckConnection;
